<template>
  <div class="tw-bg-white tw-py-8 tw-px-8 sm:tw-px-12 md:tw-px-24 tw-text-primary">
    <div class="tw-flex tw-flex-col tw-items-center">
      <h1 class="tw-font-bold tw-pb-8 md;tw-pb-16">{{ sceneboard_headline }}</h1>
      <img :src="sceneboardImage" alt="" class="tw-rounded-xl lg:tw-max-w-4xl" />
    </div>
    <div class="tw-grid md:tw-grid-cols-3 tw-gap-4 md:tw-gap-12 tw-py-6 md:tw-py-12">
      <div v-for="(point, index) in sceneboardData" :key="index" class="">
        <img :src="point.image" class="tw-size-16" />
        <p class="tw-font-semibold tw-text-xl">{{ point.title }}</p>
        <p>{{ point.description }}</p>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-center">
      <div>
        <button
          class="tw-text-sm md:tw-text-base tw-bg-primary tw-rounded-lg tw-px-6 tw-py-2 md:tw-px-12 md:tw-py-4 tw-cursor-pointer tw-font-bold hover:tw-bg-primary/50"
          @click="scrollTo(sceneElementRef)"
        >
          <p ref="sceneElementRef" class="tw-text-white tw-no-underline tw-uppercase tw-m-0">
            {{ sceneboardCTAText }}
          </p>
        </button>
      </div>
    </div>
  </div>
  <div
    class="tw-w-auto tw-bg-mainGray tw-flex tw-flex-col tw-py-8 tw-px-8 sm:tw-px-12 md:tw-px-24 tw-text-primary"
  >
    <h1 class="tw-text-2xl md:tw-text-4xl tw-font-bold tw-py-8 tw-m-0">
      {{ fnbHeadline }}
    </h1>
    <h2 class="md:tw-text-md tw-text-base tw-m-0 md:tw-pb-12">
      {{ fnbDescription }}
    </h2>
    <div class="row">
      <div class="col-lg-7 tw-flex tw-flex-row tw-justify-center tw-items-center">
        <!-- Render the image based on the active tab on the accordion -->
        <!-- <img :src="imagePath" :alt="activeTab" class="max-w-3/4 h-96 object-contain" /> -->
        <img
          :src="activeFNBTab.image"
          :alt="activeFNBTab.name"
          class="tw-size-72 md:tw-h-[530px] md:tw-w-full tw-object-contain tw-py-8"
        />
      </div>
      <div class="col-lg-5 tw-flex tw-flex-col tw-gap-4">
        <div v-for="(feature, index) in fnbData" :key="index">
          <SceneOptionBox
            :is-active="activeFNBTab.name == feature.name"
            :on-change-active-tab="onChangeFNBActiveTab"
            :feature="feature"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, useTemplateRef } from 'vue';
import SceneOptionBox from './SceneOptionBox.vue';
import getSinglePageInfo from '@/lib/getSinglePageInfo';
import targetData from '@/lib/targetData';
import fetchImageUrl from '@/lib/fetchImageUrl';
const sceneboard_headline = ref('');
const sceneboardImage = ref('');
const sceneboardData = ref();
const sceneboardCTAText = ref('');
const fnbHeadline = ref<string>('');
const fnbDescription = ref<string>('');
const sceneElementRef = useTemplateRef('sceneElementRef');
const fnbData = ref<Array<any>>([]);
const activeFNBTab = ref<any>({});
const onChangeFNBActiveTab = (tab: any) => {
  activeFNBTab.value = tab;
};

async function processSceneboardData() {
  const pageInfo = await getSinglePageInfo('features');
  console.log(pageInfo);
  const targetSceneboardHeadline: any = targetData(pageInfo, 'sceneboard_headline');
  const targetSceneboardImageId: any = targetData(pageInfo, 'sceneboard_image');
  const targetSceneboardImage: any = await fetchImageUrl(
    targetSceneboardImageId?.sceneboard_image?.image_id || '',
  );

  const targetSceneboardData: any = targetData(pageInfo, 'sceneboard_point_group');
  const targetSceneboardCTA: any = targetData(pageInfo, 'sceneboard_cta_text');
  const processedSceneboardData = await Promise.all(
    targetSceneboardData?.sceneboard_point_group?.map(async (point: any) => {
      const title = point.parts.find((part: any) => part.name === 'sceneboard_title');
      const description = point.parts.find((part: any) => part.name === 'sceneboard_description');
      const image = point.parts.find((part: any) => part.name === 'sceneboard_point_image');
      return {
        title: title?.content || '',
        description: description?.content.replace(/\n/g, ' ') || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
      };
    }) || [],
  );
  sceneboard_headline.value = targetSceneboardHeadline.sceneboard_headline;
  sceneboardImage.value = targetSceneboardImage;
  sceneboardData.value = processedSceneboardData;
  sceneboardCTAText.value = targetSceneboardCTA.sceneboard_cta_text;
  console.log(targetSceneboardCTA);
}

function extractContentData(data: any) {
  // Using map to create a new array with just the content
  return data.map((data: any) => data.parts[0].content);
}

async function processScenes() {
  const pageInfo = await getSinglePageInfo('features');
  const targetHeadlineData: any = targetData(pageInfo, 'features_and_benefits_headline');
  const targetDescriptionData: any = targetData(pageInfo, 'features_and_benefits_description');
  const targetSceneData: any = targetData(pageInfo, 'scene_group');
  const processedData = await Promise.all(
    targetSceneData?.scene_group?.map(async (scene: any) => {
      const name = scene.parts.find((part: any) => part.name === 'scene_name');
      const description = scene.parts.find((part: any) => part.name === 'scene_description');
      const points = scene.parts.find((part: any) => part.name === 'scene_points');
      const image = scene.parts.find((part: any) => part.name === 'scene_image');

      return {
        name: name?.content || '',
        description: description?.content || '',
        applications: extractContentData(points?.content) || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
      };
    }) || [],
  );
  fnbData.value = processedData;
  fnbHeadline.value = targetHeadlineData.features_and_benefits_headline;
  fnbDescription.value = targetDescriptionData.features_and_benefits_description;
  activeFNBTab.value = processedData[0];
}

function scrollTo(view: any) {
  view.scrollIntoView({ behavior: 'smooth' });
}
// Initiate interval for auto-change of slides when the component is mounted
onMounted(() => {
  processSceneboardData();
  processScenes();
});
</script>
<style></style>
