<template>
  <div
    v-if="!isSubmitted"
    class="tw-pt-24 md:tw-pt-36 tw-pb-24 tw-px-8 tw-bg-mainGray !tw-m-0 tw-flex tw-flex-col tw-items-center"
  >
    <div
      class="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-primary tw-px-12 md:!tw-px-18 tw-max-w-[950px] tw-pb-4 md: tw-pb-6"
    >
      <h1 class="tw-text-3xl md:tw-text-5xl tw-pb-4 tw-font-bold">Let's Get In Touch!</h1>
      <div class="tw-text-start">
        <p class="tw-text-lg md:tw-text-xl tw-font-light">
          Book a demo to see how our services can best suit your business. We believe that
          technology has the power to change the way we live, and we're working hard every day on
          making that happen.
        </p>
        <p class="tw-text-lg md:tw-text-xl tw-font-light">
          For press inquiries, please select "Press Contact" to reach out to our press contact for
          more information.
        </p>
        <p class="tw-text-lg md:tw-text-xl tw-font-light">
          Or reach out to us at
          <a class="tw-text-primary tw-font-semibold" href="mailto:info@scenera.net"
            >info@scenera.net</a
          >.
        </p>
      </div>
    </div>
    <form
      class="tw-rounded-3xl tw-bg-contactGreen tw-py-8 md:!tw-py-20 tw-px-12 md:!tw-px-28 tw-max-w-[1000px] tw-font-semibold tw-text-white tw-flex tw-flex-col tw-gap-6 tw-shadow-lg"
      novalidate
      @submit.prevent="onSubmit"
    >
      <div>
        <p>I'm interested in...</p>
        <div class="tw-font-normal tw-flex tw-gap-4">
          <Option_button
            text="Book a demo"
            :is-active="state.interest === 'Book a demo'"
            :on-click="onInterestBtnClick"
          />
          <Option_button
            text="Press Contact"
            :is-active="state.interest === 'Press Contact'"
            :on-click="onInterestBtnClick"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>First Name*</p>
          <input
            v-model="state.firstName"
            type="text"
            class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
            placeholder="John"
            @blur="v$.firstName.$touch"
          />
          <div
            v-if="v$.firstName.$error"
            class="tw-text-sm tw-pt-2 tw-font-bold tw-text-white tw-underline tw-italic"
          >
            First Name is required.
          </div>
        </div>
        <div class="col">
          <p>Last Name*</p>
          <input
            v-model="state.lastName"
            type="text"
            class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
            placeholder="Doe"
            @blur="v$.lastName.$touch"
          />
          <div
            v-if="v$.lastName.$error"
            class="tw-text-sm tw-pt-2 tw-font-bold tw-text-white tw-underline tw-italic"
          >
            Last Name is required.
          </div>
        </div>
      </div>
      <div class="tw-w-full">
        <p>Job Title*</p>
        <input
          v-model="state.jobTitle"
          type="text"
          class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-py-3 tw-px-4 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="Facility Manager"
          @blur="v$.jobTitle.$touch"
        />
        <div
          v-if="v$.jobTitle.$error"
          class="tw-text-sm tw-pt-2 tw-font-bold tw-text-white tw-underline tw-italic"
        >
          Job title is required.
        </div>
      </div>
      <div>
        <p>Company Name*</p>
        <input
          v-model="state.companyName"
          type="text"
          class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="XYZ Company"
          @blur="v$.companyName.$touch"
        />
        <div
          v-if="v$.companyName.$error"
          class="tw-text-sm tw-pt-2 tw-font-bold tw-text-white tw-underline tw-italic"
        >
          Company name is required.
        </div>
      </div>
      <div>
        <p>Email*</p>
        <input
          v-model="state.email"
          type="email"
          class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="johndoe@yourcompany.com"
          @blur="v$.email.$touch"
        />
        <div
          v-if="v$.email.$error"
          class="tw-text-sm tw-pt-2 tw-font-bold tw-text-white tw-underline tw-italic"
        >
          Please enter a valid email address.
        </div>
      </div>
      <div>
        <p>Phone Number</p>
        <input
          v-model="state.phoneNumber"
          type="text"
          class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="(123)456-7890"
        />
      </div>
      <div>
        <p>State/Region*</p>
        <input
          v-model="state.state"
          type="text"
          class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="California"
          @blur="v$.state.$touch"
        />
        <div
          v-if="v$.state.$error"
          class="tw-text-sm tw-pt- tw-font-bold tw-text-white tw-underline tw-italic"
        >
          State/Region is required.
        </div>
      </div>
      <div>
        <p>Country/Region</p>
        <input
          v-model="state.country"
          type="text"
          class="tw-py-3 tw-px-4 tw-text-primary focus:tw-ring-primary tw-w-full tw-border-0 tw-rounded-2xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="USA"
        />
      </div>
      <div class="tw-font-normal">
        <label class="tw-m-0 tw-mb-4 tw-font-semibold">
          <input
            v-model="state.consent"
            type="checkbox"
            class="checked:!tw-bg-primary focus:tw-ring-primary tw-mr-2"
            @change="v$.consent.$touch()"
          />
          I agree to receive communications from Scenera.net*
        </label>
        <p>
          By submitting this form you agree to receive communications from Scenera.net and agree to
          allow Scenera.net to store and process your personal data.
        </p>
        <div
          v-if="v$.consent.$error"
          class="tw-text-sm tw-pt-2 tw-font-bold tw-text-white tw-underline tw-italic"
        >
          Consent is required to proceed.
        </div>
      </div>
      <div>
        <RecaptchaV2
          @widget-id="handleWidgetId"
          @error-callback="handleErrorCallback"
          @expired-callback="handleExpiredCallback"
          @load-callback="handleLoadCallback"
        />
        <div
          v-if="showWarningA"
          class="tw-text-sm tw-pt-2 tw-m-0 tw-font-bold tw-text-white tw-underline tw-italic"
        >
          Please click on the reCAPTCHA to proceed.
        </div>
        <div
          v-if="showWarningB"
          class="tw-text-sm tw-m-0 tw-font-bold tw-text-white tw-underline tw-italic"
        >
          You did not pass the reCAPTCHA test.
        </div>
      </div>
      <button
        class="tw-py-3 tw-w-full tw-bg-primary tw-rounded-3xl tw-text-white tw-font-bold tw-shadow-lg hover:tw-bg-primary/70"
      >
        Submit Form
      </button>
    </form>
  </div>
  <div
    v-if="isSubmitted"
    class="tw-pt-48 tw-pb-24 tw-px-12 md:tw-px-24 row tw-bg-mainGray tw-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center"
  >
    <div class="!tw-max-w-5xl">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-text-white tw-rounded-xl tw-bg-contactGreen tw-font-semibold tw-shadow-lg tw-gap-6 tw-py-16 tw-px-8 n tw-text-center tw-justify-center tw-align-center"
      >
        <h1 class="tw-text-4xl md:tw-text-6xl">Thank you for reaching out!</h1>
        <h2 class="tw-text-2xl">Someone from our team will be in touch with you soon!</h2>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import axios from 'axios';
import { ref, reactive, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs } from '@vuelidate/validators';
import { RecaptchaV2 } from 'vue3-recaptcha-v2';
import Option_button from './button/Option_button.vue';
const recaptchaResponse = ref<any>('');
const showWarningA = ref<boolean>(false);
const showWarningB = ref<boolean>(false);
const isTokenValid = ref<boolean>(false);
const score = ref<number>(0);
const GOOGLE_CLOUD_PROJECT_ID = import.meta.env.VITE_GOOGLE_CLOUD_PROJECT_ID;
const GOOGLE_CLOUD_APIKEY = import.meta.env.VITE_GOOGLE_CLOUD_APIKEY;
const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const handleWidgetId = (widgetId: number) => {
  console.log('Widget ID: ', widgetId);
};
const handleErrorCallback = () => {
  console.log('Error callback');
};
const handleExpiredCallback = () => {
  console.log('Expired callback');
};
const handleLoadCallback = (response: unknown) => {
  recaptchaResponse.value = response;
};

const state = reactive({
  interest: 'Book a demo',
  firstName: '',
  lastName: '',
  jobTitle: '',
  companyName: '',
  email: '',
  phoneNumber: '',
  state: '',
  country: '',
  consent: false,
});
const isSubmitted = ref<boolean>(false);

const rules = {
  firstName: { required, $lazy: true },
  lastName: { required, $lazy: true },
  jobTitle: { required, $lazy: true },
  companyName: { required, $lazy: true },
  email: { required, email, $lazy: true },
  state: { required, $lazy: true },
  consent: {
    sameAs: sameAs(true),
  },
};

const v$ = useVuelidate(rules, state);

function onInterestBtnClick(interest: string) {
  state.interest = interest;
}

watch(recaptchaResponse, async (newValue) => {
  verifyRecaptchaToken(newValue);
});

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect && recaptchaResponse.value != '') {
    console.log('submit');
    sendEmail();
    isSubmitted.value = true;
  } else if (recaptchaResponse.value == '') {
    showWarningA.value = true;
  } else if (isTokenValid.value != true && score.value < 0.6) {
    showWarningB.value = true;
  } else {
    console.log('error');
  }
}

const formData = state;

function sendEmail() {
  axios
    .post('/api/v1/send_email', formData)
    .then((response) => {
      const message = response.data.message;
      console.log(message);
    })
    .catch((error) => {
      const errorMsg = error.response.data.error || 'An error occurred while sending the email';
      console.log(errorMsg);
    });
}

const verifyRecaptchaToken = async (token: string) => {
  try {
    // Construct the request payload
    const requestBody = {
      event: {
        token: token,
        expectedAction: 'form_submit',
        siteKey: RECAPTCHA_SITE_KEY,
      },
    };

    // Send verification request to Google reCAPTCHA Enterprise
    const response = await axios.post(
      `https://recaptchaenterprise.googleapis.com/v1/projects/${GOOGLE_CLOUD_PROJECT_ID}/assessments?key=${GOOGLE_CLOUD_APIKEY}`,
      requestBody,
    );

    // Extract and return the assessment results
    const assessment = response.data;

    // Check if the token is valid and the score meets your threshold
    const isTokenValidLocal = assessment.tokenProperties?.valid || false;
    const scoreLocal = assessment.riskAnalysis?.score || 0;

    isTokenValid.value = isTokenValidLocal;
    score.value = scoreLocal;

    return {
      valid: isTokenValid,
      score: score,
    };
  } catch (error: any) {
    console.error('reCAPTCHA verification failed:', error);
    return {
      valid: false,
      score: 0,
      error: error.message,
    };
  }
};
</script>
<style></style>
