<template>
  <div class="tw-bg-white tw-text-primary tw-py-8 tw-px-8 sm:tw-px-12 md:tw-px-24">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-center">
      <div v-for="(aiPoint, index) in aiData" :key="index">
        <AIOptionBox
          :is-active="activeAITab.title === aiPoint.title"
          :on-change-active-tab="onChangeActiveTab"
          :ai-point="aiPoint"
        />
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-center">
      <div class="row tw-h-full tw-justify-center md:tw-pt-16">
        <div class="col-md-6 !tw-p-0">
          <div
            class="tw-py-8 lg:tw-py-0 md:!tw-px-14 tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-h-96"
          >
            <img
              :src="activeAITab.image"
              class="md:tw-w-[650px] tw-object-contain tw-rounded md:tw-max-h-[370px]"
            />
          </div>
        </div>
        <div
          class="col-md-6 md:tw-h-96 tw-flex tw-flex-col md:!tw-px-0 tw-justify-center tw-items-center"
        >
          <div class="md:!tw-w-96">
            <h3 class="tw-text-lg tw-font-bold tw-py-2 md:tw-py-4 tw-m-0">
              {{ activeAITab.title }}
            </h3>
            <ul class="tw-list-inside tw-list-disc tw-pt-4 !tw-pl-0">
              <li
                v-for="(point, index) in activeAITab.points"
                :key="index"
                class="tw-text-md tw-m-0"
              >
                {{ point }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import AIOptionBox from './AIOptionBox.vue';
import getSinglePageInfo from '@/lib/getSinglePageInfo';
import targetData from '@/lib/targetData';
import fetchImageUrl from '@/lib/fetchImageUrl';

async function processData() {
  const pageInfo = await getSinglePageInfo('features');
  console.log(pageInfo);
  // const targetCtaTextData: any = targetData(pageInfo, 'cta');
  // const targetCtaLinkData: any = targetData(pageInfo, 'cta_link');

  const targetAiData: any = targetData(pageInfo, 'features_ai_point_group');

  function extractContentData(data: any) {
    // Using map to create a new array with just the content
    return data.map((data: any) => data.parts[0].content);
  }
  const processedData = await Promise.all(
    targetAiData?.features_ai_point_group?.map(async (point: any) => {
      const title = point.parts.find((part: any) => part.name === 'features_ai_title');
      const description = point.parts.find((part: any) => part.name === 'features_ai_description');
      const image = point.parts.find((part: any) => part.name === 'features_ai_image');
      const details = point.parts.find((part: any) => part.name === 'features_ai_details_group');
      const processedSubpoints = extractContentData(details.content);
      return {
        title: title?.content || '',
        description: description?.content.replace(/\n/g, ' ') || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
        points: processedSubpoints || '',
      };
    }) || [],
  );
  // console.log(targetAiData)
  // console.log(processedData)
  activeAITab.value = processedData[0];
  aiData.value = processedData;
}
const activeAITab: any = ref({});
const aiData: any = ref([]);

function onChangeActiveTab(point: object) {
  activeAITab.value = point;
}

// Initiate interval for auto-change of slides when the component is mounted
onMounted(() => {
  processData();
});
</script>
<style></style>
