<template>
  <div
    class="features tw-pt-[72px] md:tw-pt-[82px] tw-py-8 tw-px-8 sm:tw-px-12 md:tw-px-24 tw-bg-mainGray"
  >
    <div
      class="tw-py-4 md:tw-py-8 tw-flex tw-flex-col tw-items-center tw-text-center tw-gap-4 md:tw-gap-10"
    >
      <div class="tw-w-full md:tw-max-w-[720px] tw-text-primary">
        <h1 class="tw-mb-4 md:tw-mb-6 tw-text-3xl md:tw-text-5xl tw-font-light">
          Empower Smart Buildings with
        </h1>
        <h1 class="tw-font-semibold">Scenera MAIstro</h1>
      </div>
      <Cta_button cta-text="Contact Us" cta-link="/contact-us" />
    </div>
  </div>
  <div class="tw-bg-white tw-text-primary tw-px-10 lg:tw-px-28 tw-py-16">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-center">
      <div v-for="(aiPoint, index) in aiData" :key="index">
        <AIOptionBox
          :is-active="activeAITab.title === aiPoint.title"
          :on-change-active-tab="onChangeActiveTab"
          :ai-point="aiPoint"
        />
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-center">
      <div class="row tw-h-full tw-justify-center md:tw-pt-16">
        <div
          class="col-md tw-w-full sm:tw-full tw-py-8 lg:tw-py-0 lg:tw-w-3/5 md:!tw-px-14 tw-flex tw-flex-col tw-justify-center"
        >
          <img :src="activeAITab.imagePath" class="tw-max-h-80 tw-object-contain tw-rounded" />
        </div>
        <div class="col-md tw-flex tw-flex-row tw-justify-center tw-items-center md:!tw-px-14">
          <div class="!tw-max-w-96">
            <h3 class="tw-text-lg tw-font-bold tw-py-2 md:tw-py-4 tw-m-0">
              {{ activeAITab.title }}
            </h3>
            <ul class="tw-list-inside tw-list-disc tw-pt-4 !tw-pl-0">
              <li
                v-for="(point, index) in activeAITab.points"
                :key="index"
                class="tw-text-md tw-m-0"
              >
                {{ point }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tw-bg-mainGray tw-px-10 lg:tw-px-28 tw-py-16 tw-flex tw-flex-col">
    <h1 class="tw-font-bold tw-text-primary tw-mb-8 md:tw-mb-16 tw-self-center md:tw-self-start">
      Benefits
    </h1>
    <div class="row tw-gap-4 xl:tw-gap-0">
      <div v-for="(benefit, index) in benefits" :key="index" class="col-md !tw-p-0">
        <div
          class="tw-bg-gradient-to-t tw-from-gradientDarkGreen tw-to-gradientLightGreen tw-text-2xl tw-text-white tw-w-60 md:tw-w-[280px] tw-h-80 md:tw-h-[343px] tw-py-6 md:tw-py-8 tw-px-6 tw-rounded-xl tw-overflow-hidden tw-justify-self-center"
        >
          <div class="tw-overflow-hidden">
            <div class="hover:tw--translate-y-52 tw-transition-all">
              <div class="tw-w-full tw-flex tw-justify-center">
                <img :src="benefit.iconPath" class="tw-py-4 tw-max-h-52" />
              </div>

              <p class="tw-text-xl md:tw-text-3xl tw-font-bold tw-mt-0 tw-mb-28 tw-pt-4">
                {{ benefit.name }}
              </p>
              <p class="tw-text-lg md:tw-text-xl tw-font-semibold">
                {{ benefit.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tw-bg-white tw-px-10 lg:tw-px-56 tw-py-16 tw-text-primary">
    <div class="tw-flex tw-flex-col tw-items-center">
      <h1 class="tw-font-bold tw-pb-8 md;tw-pb-16">People First Innovation Sceneboard</h1>
      <img src="./asset/sceneboard.png" alt="" class="tw-rounded-xl" />
    </div>
    <div class="tw-grid md:tw-grid-cols-3 tw-gap-4 md:tw-gap-12 tw-py-6 md:tw-py-12">
      <div v-for="(point, index) in sceneboardPoints" :key="index" class="">
        <img :src="point.imagePath" class="tw-size-16" />
        <p class="tw-font-semibold tw-text-xl">{{ point.name }}</p>
        <p>{{ point.description }}</p>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-center">
      <div>
        <button
          class="tw-text-base tw-bg-primary tw-rounded-lg tw-px-12 tw-py-4 tw-cursor-pointer tw-font-bold"
          @click="scrollTo(sceneElementRef)"
        >
          <p class="tw-text-white tw-no-underline tw-uppercase tw-m-0">Explore All Scenes</p>
        </button>
      </div>
    </div>
  </div>
  <div ref="sceneElementRef">
    <div
      class="tw-w-auto tw-bg-mainGray tw-flex tw-flex-col tw-py-8 tw-px-8 sm:tw-px-12 md:tw-px-24 tw-text-primary"
    >
      <h1 class="tw-text-2xl md:tw-text-4xl tw-font-bold tw-py-8 tw-m-0">
        {{ fnbHeadline }}
      </h1>
      <h2 class="md:tw-text-md tw-text-base tw-m-0 md:tw-pb-12">
        {{ fnbDescription }}
      </h2>
      <div class="row">
        <div class="col-md-7 tw-flex tw-flex-row tw-justify-center tw-items-center">
          <!-- Render the image based on the active tab on the accordion -->
          <!-- <img :src="imagePath" :alt="activeTab" class="max-w-3/4 h-96 object-contain" /> -->
          <img
            :src="activeFNBTab.imagePath"
            :alt="activeFNBTab.title"
            class="tw-size-72 md:tw-h-[530px] md:tw-w-full tw-object-contain tw-py-8"
          />
        </div>
        <div class="col-md-5 tw-flex tw-flex-col tw-gap-4">
          <div v-for="(feature, index) in fnbList" :key="index">
            <SceneOptionBox
              :is-active="activeFNBTab.title == feature.title"
              :on-change-active-tab="onChangeFNBActiveTab"
              :feature="feature"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, useTemplateRef } from 'vue';
import Cta_button from './button/Cta_button.vue';
import AIOptionBox from './AIOptionBox.vue';
import SceneOptionBox from './SceneOptionBox.vue';
const sceneElementRef = useTemplateRef('sceneElementRef');
const activeFNBTab = ref<any>({
  title: ' EnergyScene',
  imagePath: '/energyScene.png',
  description: 'Maximize energy savings to boost efficiency and reduce costs',
  points: [
    'Energy Usage Trends',
    'Occupancy',
    'Correlational Analyses',
    'Predictions',
    'Anomalies',
  ],
});
const fnbHeadline = ref<string>(
  'Unlock the Full Potential of Your Facility’s Operational Data with Four Core Scenes',
);
const fnbDescription = ref<string>(
  'Explore Scenera MAIstro’s four key Scenes—Energy, Maintenance, Event, and ROI—designed to optimize every aspect of your facility management. Each Scene offers targeted insights and actionable data, empowering you to maximize efficiency, enhance safety, and boost profitability.',
);

function scrollTo(view: any) {
  view.scrollIntoView({ behavior: 'smooth' });
}

const aiData = [
  {
    title: 'Orchestrating IOT Data with AI-Powered Insights',
    description: 'Some description about this AI feature. Test Test Test.',
    imagePath: 'fh_orchestration.jpg',
    points: [
      'Energy Management: Optimize power usage in real-time.',
      'Equipment Maintenance: Predict and prevent issues with cloud-based analytics.',
      'Customer Behavior: Understand interactions and trends.',
      'Sales Analysis: Enhance strategies with deep trend analysis.',
      'Factory Safety Monitoring: Ensure compliance with real-time alerts.',
    ],
  },
  {
    title: 'Advanced AI Capabilities',
    description: 'Some description about this AI feature',
    imagePath: 'fh_peopleFirst.png',
    points: [
      'Copilot Analytics Integration: Assess valuable insights from complex dataset for recommended actions',
      'Generative AI Integration: Create intelligent prompts for deeper data analysis.',
      'Privacy-Centric Solutions: Deliver actionable insights while protecting sensitive information.',
    ],
  },
];

const fnbData = [
  {
    title: ' EnergyScene',
    imagePath: '/energyScene.png',
    description: 'Maximize energy savings to boost efficiency and reduce costs',
    points: [
      'Energy Usage Trends',
      'Occupancy',
      'Correlational Analyses',
      'Predictions',
      'Anomalies',
    ],
  },
  {
    title: 'MaintenanceScene',
    imagePath: '/maintenanceScene.png',
    description: 'Enhance uptime to ensure optimal performance and minimize disruptions',
    points: ['HVAC Health', 'Refrigeration Health', 'In-store Traffic & Occupancy'],
  },
  {
    title: 'EventScene',
    imagePath: '/eventScene.png',
    description: 'Strengthen security to maintain a safe environment.',
    points: ['Queue Monitor', 'Slip, Trip, and Fall', 'Heat map', 'Intrusion'],
  },
  {
    title: 'ROIScene',
    imagePath: '/roiScene.png',
    description: 'Drive profitability to ensure a high return on investment.',
    points: [
      'Food Waste Monitor',
      'Revenue Monitor',
      'Customer Satisfaction',
      'Staffing Management',
    ],
  },
];

const fnbList = ref<Array<any>>(fnbData);
// Change the active tab
const onChangeFNBActiveTab = (tab: any) => {
  activeFNBTab.value = tab;
};

const activeAITab: any = ref(aiData[0]);

function onChangeActiveTab(point: object) {
  activeAITab.value = point;
}

const benefits = [
  {
    name: 'Real-Time Monitoring',
    description: 'Stay ahead of potential issues with live system alerts.',
    iconPath: '/technician.png',
  },
  {
    name: 'AI-Powered Insights',
    description: 'Predict trends and detect anomalies for optimal performance.',
    iconPath: '/technician.png',
  },
  {
    name: 'Customizable Dashboards',
    description: 'Visualize your data and track KPIs effortlessly.',
    iconPath: '/technician.png',
  },
  {
    name: 'Energy Efficiency',
    description: 'Achieve sustainability goals with actionable recommendations.',
    iconPath: '/technician.png',
  },
];

const sceneboardPoints = [
  {
    name: 'Revolutionizing Data Visualization',
    description:
      "Scenera redefines the user experience with 'SceneBoards'—a people-first, dynamic interface that puts decision-makers in control of actionable insights.",
    imagePath: '/data-management.png',
  },
  {
    name: 'User-Centric Design',
    description: 'Intuitive and simple for all users, regardless of technical expertise.',
    imagePath: '/data-management.png',
  },
  {
    name: 'Intuitive Visualization',
    description: 'Go beyond static charts with visuals that tell a story.',
    imagePath: '/data-management.png',
  },
  {
    name: 'Dynamic Interaction',
    description: 'Real-time engagement with data, enabling personalized and adaptive experiences.',
    imagePath: '/data-management.png',
  },
  {
    name: 'Personalized Decision Frameworks',
    description: 'Tailor the interface to align with specific business objectives.',
    imagePath: '/data-management.png',
  },
  {
    name: 'Streamlined Decision-Making',
    description: 'Quickly identify trends and anomalies to enhance operational efficiency.',
    imagePath: '/data-management.png',
  },
];
</script>
<style></style>
